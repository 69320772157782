import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { GetApi } from '../../Api/Api';
import { useSnackbar } from "../../Contexts/SnackbarContext";
import SliderCard from './SliderCard';

const AssetsSliderProjectProposer = () => {
  // State to store category data
  const [categoryData, setCategoryData] = useState()

  const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""

  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    // Function to fetch category data from the API
    async function getCategoryData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        if (res?.data?.data && res?.data?.data?.length) {
          setCategoryData(res?.data?.data)
        } else {
          setCategoryData([])
        }
      } catch (error) {
        showSnackbar('Error in fetching category details', 'error')
      }
    }
    getCategoryData();
  }, [])

  const handleSelect = (assetType, category_id) => {
    if (assetType === "watch" || assetType === "art" || assetType === 'tokenize' || assetType === "gold" || assetType === "funds" || assetType === "real-estate" || assetType === "car" ) {
      navigate(localData?.id ? '/questionnaire-flow' : "/login", { state: { selectedCategory: assetType, category_id: category_id, from: "/project-proposer" } });
    }
  }
  return (
    <>
      <Grid container spacing={2}>
        {categoryData?.length ?
          categoryData?.map((val, ind) => {
            return <Grid item key={ind} xs={12} sm={6} md={12 / categoryData?.length} onClick={() => handleSelect(val.url, val.id)}>
              <Box className="hc-thumbnail-wrap">
                {console.log("val...",val)}
                <SliderCard image={`${process.env.REACT_APP_IMAGE_URL}${val?.category_image?.path}`} title={val.title} height={'61.64%'} assetType={(val.url === "watch" || val.url === "art" || val.url === 'tokenize' || val.url === "gold" || val.url === "funds" || val.url === "real-estate" || val.url === "car") ? false : true} />
              </Box>
            </Grid>
          }) : <></>}
      </Grid>
    </>
  )
}

export default AssetsSliderProjectProposer;