//  spaceXFundTokenTotalSupply.js

import { readContract } from "@wagmi/core";
import convertFromDecimal from "../Common/convertFromDecimal";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} address user wallet address
 * @param {String} contract_address asset data
 * @returns user wallet 
 */
async function spaceXFundTokenTotalSupply(address, contract_address, contract_abi) {
    try {
        // Now check for
        const balanceOf = await readContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            functionName: "totalSupply",
            chainId: 6806
        })
        return convertFromDecimal(Number(balanceOf));
    } catch (error) {
        console.error('Error fetching allowance:', error);
        throw error; // propagate the error
    }
}

export { spaceXFundTokenTotalSupply };