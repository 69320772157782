// variable to switch between testnet and polygon
var isMainnetProduction = false;
/**
* @file overview This file contains the contract addresses and ABI's
*/

/**
* @constant {object} SP_CONTRACTS - The contract addresses
*/
export const SP_CONTRACTS = {
    ADMIN: isMainnetProduction ? "" : '0xE803Cea326b54708f45b453C38876c54B106a7Ae',
    SERVICE_PROVIDER_REGISTRY: isMainnetProduction ? "" : '0xC2867A26A1048850A05A776445C4a45A2dF488C3',
    SERVICE_MARKETPLACE_CLIENT: isMainnetProduction ? "" : '0x270344Ac6423DA1D0850CE0C49E498105D7093c9',
    SERVICE_MARKETPLACE_PROVIDER: isMainnetProduction ? "" : '0x1354494037b0e6647920aBCDb0fA387e9af01881',
    SERVICE_STAKING_CONTRACT: isMainnetProduction ? "" : '0x4d8f923D572Ef0C514788B1c9f9C1eFFB712502D',
    SERVICE_ESCROW_WALLET: isMainnetProduction ? "" : '0x856526e35d1DbA882A440c7B98440e645a1fbCa7',
}

/**
 * @constant {object} CONTRACT_ABI - The contract ABI's
 */
export const SP_CONTRACT_ABI = {
    [SP_CONTRACTS.ADMIN]: isMainnetProduction ? require("./Abi/AdminRT.json") : require("./Abi/Admin.json"),
    [SP_CONTRACTS.SERVICE_PROVIDER_REGISTRY]: isMainnetProduction ? require("./Abi/registrationRT.json") : require("./Abi/registration.json"),
    [SP_CONTRACTS.SERVICE_MARKETPLACE_CLIENT]: isMainnetProduction ? require("./Abi/servicemarketplaceRT.json") : require("./Abi/servicemarketplace.json"),
    [SP_CONTRACTS.SERVICE_MARKETPLACE_PROVIDER]: isMainnetProduction ? require("./Abi/serviceMarketPlaceServiceProviderRT.json") : require("./Abi/serviceMarketPlaceServiceProvider.json"),
    [SP_CONTRACTS.SERVICE_STAKING_CONTRACT]: isMainnetProduction ? require("./Abi/serviceStakingRT.json") : require("./Abi/serviceStaking.json"),
    [SP_CONTRACTS.SERVICE_ESCROW_WALLET]: isMainnetProduction ? require("./Abi/ServiceEscrowWalletRT.json") : require("./Abi/ServiceEscrowWallet.json"),
}