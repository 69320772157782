import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { GetApi } from '../../Api/Api'
import { useSnackbar } from '../../Contexts/SnackbarContext'
import { CATEGORY_TYPE_ID } from '../../constants'
import TokenizedAssetsCard from './TokenizedAssets/TokenizedAssetsCard'

const TokenizedAssetsSection = (props) => {
    const [categoryData, setCategoryData] = useState()
    const [cardSelected, setCardSelected] = useState('')
    const { showSnackbar } = useSnackbar();

    // useEffect for calling api's
    useEffect(() => {
        async function getCategoryData() {
            try {
                // Call the API to fetch category data
                const res = await GetApi("/proposer/newAssetsCategoryGet/");

                // Check if the response contains data and it's an array with length
                if (res?.data?.data && res?.data?.data?.length) {
                    setCategoryData(res?.data?.data); // Set the fetched data into the state
                } else {
                    setCategoryData([]); // If no data, set an empty array
                }
            } catch (error) {
                // Show a snackbar notification in case of an error
                showSnackbar('Error in fetching category details', 'error')
            }
        }
        setTimeout(() => {
            getCategoryData(); // Call the function to fetch data
        }, 100)
    }, [showSnackbar]);

    // Function to handle the selection of a card
    const handleSelect = (_, id, category_id) => {
        setCardSelected(cardSelected === id ? "" : id);
        // Update the selected category based on the card selection
        props?.setSelectedCategory(cardSelected === id ? "" : category_id);
    }

    return (
        <Grid container spacing={2} >
            {categoryData?.length ?
                categoryData?.map((val, ind) => {
                    if (val.id !== CATEGORY_TYPE_ID.ASSET) {
                        return <Grid item key={ind} xs={12} sm={6} md={(2.4)} onClick={() => handleSelect(val.url, val.id, val.category_id)} >
                            <TokenizedAssetsCard
                                imgSrc={`${process.env.REACT_APP_IMAGE_URL}${val.category_image.path}`}
                                title={val.title}
                                description={val.data}
                                cardSelected={cardSelected === val.id ? 'active' : ''}
                                positionFromTop={props?.positionFromTop}
                            />
                        </Grid>
                    } else {
                        return null
                    }
                }) : <></>}
        </Grid>
    )
}

export default TokenizedAssetsSection