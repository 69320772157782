import React from "react";
import { Box, Typography, Divider, Stack, useMediaQuery, Grid } from "@mui/material";
import { CAPITAL_TYPE_ID } from "../../constants";
import { formatNumber } from "../../Components/Common/USFormat";

const CapitalInfo = ({ capitalInfo }) => {
  const isXs = useMediaQuery('(max-width:600px)');

  return (
    <Box className="pd-sidebar-priceOverview" pt={0.8} pb={1.5}>
      <Divider />
      {capitalInfo?.map((val, ind) => {
        return (
          <>
            <Box
              mt={2.5}
              justifyContent={"space-between"}
              display={"flex"}
              alignItems={"center"}
            >
              {/* <Box className="font-18">{val.capital_type.capital_name}</Box> */}
              <Box className="font-18">{val?.class ? `Class ${parseInt(val?.class) === 1 ? 'A' : 'B'}` : val?.capital_type?.capital_name}</Box>
            </Box>
            <>
              <Grid container spacing={0} mb={2} mt={0.7} mx={1}>
                {/* {val?.class ? null : */}
                <Grid item md={8} sm={6} xs={12}>
                  <Stack spacing={1} >
                    <Typography className={isXs ? "price font-24" : "price font-28"}>
                      ${formatNumber(val?.total_raise ? val?.total_raise : 0)}
                    </Typography>
                    <Typography
                      className="title font-14 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Total Raise
                    </Typography>
                  </Stack>
                </Grid>
                {/* } */}
                {val?.capital_type?.id === CAPITAL_TYPE_ID.SENIOR_DEBT && (
                  <Grid item md={4} sm={6} xs={12}>
                    <Stack spacing={1}>
                      <Typography className={isXs ? "price font-24" : "price font-28"}>
                        {val?.senior_duration} {val?.senior_duration > 1 ? 'Years' : 'Year'}
                      </Typography>
                      <Typography
                        className="title font-14 text-blueGray-400 text-capitalize"
                        mt={0.3}
                      >
                        Duration of Debt
                      </Typography>
                    </Stack>
                  </Grid>
                )}
                {val?.capital_type?.id === CAPITAL_TYPE_ID.JUNIOR_DEBT && (
                  <Grid item md={4} sm={6} xs={12}>
                    <Stack spacing={1}>
                      <Typography className={isXs ? "price font-24" : "price font-28"}>
                        {val?.junior_duration} {val?.junior_duration > 1 ? 'Years' : 'Year'}
                      </Typography>
                      <Typography
                        className="title font-14 text-blueGray-400 text-capitalize"
                        mt={0.3}
                      >
                        Duration of Debt
                      </Typography>
                    </Stack>
                  </Grid>
                )}
              </Grid>

              <Grid container spacing={0} mb={2} mt={0.7} mx={1}>
                {val?.capital_type?.id !== CAPITAL_TYPE_ID.EQUITY &&
                  <Grid item md={4} sm={6} xs={12}>
                    <Stack spacing={0} mb={2}>
                      <Typography className="price font-24">
                        {val?.rate_of_return}%
                      </Typography>
                      <Typography
                        className="title font-12 text-blueGray-400 text-capitalize"
                        mt={0.3}
                      >
                        {val?.class ? "Hurdle Rate" : "Interest Rate"}
                      </Typography>
                    </Stack>
                  </Grid>
                }
                <Grid item md={4} sm={6} xs={12}>
                  <Stack spacing={0} mb={2}>
                    <Typography className="price font-24">
                      ${formatNumber(val?.minimum_investment ? val?.minimum_investment : 0)}
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Min. Investment
                    </Typography>
                  </Stack>
                </Grid>
                {val?.class ?
                  <Grid item md={4} sm={6} xs={12}>
                    <Stack spacing={0} mb={2}>
                      <Typography className="price font-24">
                        {parseFloat(val?.lp_percentage ? val?.lp_percentage : 0)}%
                      </Typography>
                      <Typography
                        className="title font-12 text-blueGray-400 text-capitalize"
                        mt={0.3}
                      >
                        LP Percentage
                      </Typography>
                    </Stack>
                  </Grid>
                  :
                  val?.capital_type?.id !== CAPITAL_TYPE_ID.EQUITY && (
                    <Grid item md={4} sm={6} xs={12}>
                      <Stack spacing={0} mb={2}>
                        <Typography className="price font-24">
                          {val?.capital_type?.id === CAPITAL_TYPE_ID.SENIOR_DEBT ? val?.iop_senior : val?.iop_junior} Months
                        </Typography>
                        <Typography
                          className="title font-12 text-blueGray-400 text-capitalize"
                          mt={0.3}
                        >
                          Interest-only Period
                        </Typography>
                      </Stack>
                    </Grid>
                  )
                }
                {val?.capital_type?.id === CAPITAL_TYPE_ID.EQUITY && (
                  <Grid item md={4} sm={6} xs={12}>
                    <Stack spacing={0} mb={2}>
                      <Typography className="price font-24">
                        {val?.equity}%
                      </Typography>
                      <Typography
                        className="title font-12 text-blueGray-400 text-capitalize"
                        mt={0.3}
                      >
                        Equity
                      </Typography>
                    </Stack>
                  </Grid>
                )}
                {([CAPITAL_TYPE_ID.SENIOR_DEBT, CAPITAL_TYPE_ID.JUNIOR_DEBT]?.includes(val?.capital_type?.id)) && (
                  <Grid item md={4}>
                    <Stack spacing={0} mb={2}>
                      <Typography className={isXs ? "price font-24" : "price font-24"}>
                        {val?.amortization_duration} {val?.amortization_duration > 1 ? 'Years' : 'Year'}
                      </Typography>
                      <Typography
                        className="title font-12 text-blueGray-400 text-capitalize"
                        mt={0.3}
                      >
                        Amortization Period
                      </Typography>
                    </Stack>
                  </Grid>
                )}
              </Grid>
              <Divider />
            </>
          </>
        );
      })}
    </Box>
  );
};

export default CapitalInfo;
