// getMinAssetApproved.js

import { readContract } from "@wagmi/core";

/**
 * Fetch user wallet balance
 * @param {String} address user wallet address
 * @returns user wallet balance
 */
async function getMintAssetApproved(address, contract_address, contract_abi, mintedAssetId) {
    try {
        const getApproved = await readContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            functionName: "getApproved",
            chainId: 6806,
            args: [
                Number(mintedAssetId) // user wallet address
            ]
        });
        return getApproved;
    } catch (error) {
        console.error('Error in fetching approved token:', error);
        throw error; // propagate the error
    }
}

export { getMintAssetApproved };
