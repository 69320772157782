import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Chip, Grid, IconButton, TextField, Stack, Typography, Modal } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getUserDataFromLocalStorage } from "../../Services/localstorage.service";
import "./ViewSingleRequest.css";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';
import RepeatIcon from '@mui/icons-material/Repeat';
import { GetApiParam, postApi } from "../../Api/Api";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import SkillChip from "../Components/Chips/SkillChip/SkillChip";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import Bookmarks from "@mui/icons-material/Bookmarks";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RightSideRaceSection from "./RightSideRaceSection";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SnackbarAlert from "../../Components/Common/SnackbarAlert";
import { calculateNewSinceCreation, calculateDaysSinceCreation, formatDate, validAmountCheck } from "../../Utils/utils";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import SpTransactionProgressModal from "../SmartContracts/SpTransactionProgressModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "var(--blue-900)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderWidth: "0px",
  borderRadius: "16px",
  "@media (max-width: 600px)": {
    width: "350px", // Set a different height for small screens
  },
  // Additional media queries for other screen sizes if needed
  "@media (min-width: 601px) and (max-width: 900px)": {
    // Custom styles for devices between 601px and 900px width
    width: "580px",
  },
  "@media (min-width: 901px) and (max-width: 1200px)": {
    // Custom styles for devices between 901px and 1200px width
    width: "750px",
    maxHeight: "300px",
    overflow: "auto",
  },
  // Specify the maximum width for large screens
  "@media (min-width: 1201px)": {
    maxWidth: "1043px",
    maxHeight: "525px",
    overflow: "auto",
  },
};

const ViewSingleRequest = () => {
  const { projectId } = useParams();
  const { showSnackbar } = useSnackbar();
  const [previousStepData, setPreviousStepData] = useState([]);
  const [similerProjectType, setSimilerProjectType] = useState([]);
  const [expandedStates, setExpandedStates] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [quoteAmount, setQuoteAmount] = useState('');
  const [quoteAmountError, setQuoteAmountError] = useState('');
  const [profilestate, setProfileState] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [propData, setPropData] = useState({});


  const localData = getUserDataFromLocalStorage();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop()
  }, [previousStepData]);

  /**
   * Checking for profile that user created profile or not
   */
  useEffect(() => {
    async function profileCheck() {
      try {
        const profileCheckResponce = await GetApiParam(
          "/services/checkProfile",
          { user_id: localData?.id ? localData?.id : "" }
        );
        setProfileState(
          profileCheckResponce?.data?.code === 200
            ? profileCheckResponce?.data?.profile
            : ""
        );
      } catch (error) {
        console.log(error);
      }
    }
    profileCheck();
  }, []);

  const getSimilerProjects = useCallback(async () => {
    const payload = {
      status: 1,
      user_id: localData?.id,
      sp_category_id: previousStepData?.sp_category_id,
      id: previousStepData?.id,
      project_notinclude: true,
      login_user_id: localData.id,
    };
    try {
      const res = await GetApiParam("/services/sp_projectRequirement", payload);
      setSimilerProjectType(res?.data?.data?.length ? res.data?.data : []);
      // Initialize expanded state for each card
      setExpandedStates(new Array(res?.data?.data?.length).fill(false));
    } catch (error) {
    }
  }, [previousStepData]);

  useEffect(() => {
    if (previousStepData) {
      getSimilerProjects();
    }
  }, [previousStepData]);

  const getProjectDetails = async () => {
    try {
      let payload = {
        status: 1,
        id: projectId,
        login_user_id: localData?.id
      };
      const projectRes = await GetApiParam(
        `/services/sp_projectRequirement`,
        payload
      );
      if (projectRes?.data && projectRes?.data?.data && projectRes?.data?.data.length > 0) {
        setPreviousStepData(projectRes?.data?.data[0] ?? {});
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (projectId) {
      getProjectDetails();
    }
  }, [projectId]);

  const handleViewMore = (ind) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[ind] = true;
      return newStates;
    });
  };

  const handleViewLess = (ind) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[ind] = false;
      return newStates;
    });
  };

  const submitRequest = () => {
    setOpenModal(true);
  }
  const redirectTonBording = () => {
    // navigate(`/service-provider/onboarding`);
    showSnackbar("Please create profile first", "warning");
  }

  const sendOffer = () => {
    const userData = getUserDataFromLocalStorage();
    const err = validAmountCheck(quoteAmount);
    if (err) {
      setQuoteAmountError(err);
      return;
    }
    console.log(quoteAmount);
    setPropData({ project_id: previousStepData?.id, spv_id: userData.id, offerAmount: quoteAmount, ...previousStepData });
    console.log({ project_id: previousStepData?.id, spv_id: userData.id, offerAmount: quoteAmount, ...previousStepData })
    setOpenTransactionModal(true);

    return
    const payload = {
      project_id: previousStepData?.id,
      spv_id: userData.id,
      offer_amount: quoteAmount
    }
    setLoading(true);
    postApi(`/services/spvOffer`, payload).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.code === 200) {
          setLoading(false);
          showSnackbar("Submitted Successfully", "success");
          setOpenModal(false);
          setQuoteAmount('');
          setQuoteAmountError('');
        } else if (res?.data?.code === 201) {
          setLoading(false);
          showSnackbar(res?.data?.message, "warning");
          setOpenModal(false);
          setQuoteAmount('');
          setQuoteAmountError('');
        } else {
          setLoading(false);
          showSnackbar(res?.data?.message, "error");
          setOpenModal(false);
          setQuoteAmount('');
          setQuoteAmountError('');
        }
      } else { // something went wrong user or backend side
        setLoading(false);
        showSnackbar("Something went wrong", "error");
      }
    })
  }

  const handleClickBookmark = async (item) => {
    const payloadData = {
      project_id: item.id,
      user_id: localData.id,
      is_bookmark: item.is_bookmark ? 0 : 1,
    };

    if (localData.id) {
      const bookmarkResponce = await postApi(
        "/services/isBookmark",
        payloadData
      );
      if (bookmarkResponce?.data?.code) {
        getSimilerProjects();
        setPreviousStepData({ ...item, is_bookmark: item.is_bookmark ? 0 : 1 });
      }
    }
  };

  const confirmStake = async () => {
    setOpenTransactionModal(false)
    console.log('success');
    setOpenModal(false);
  }

  const handleModalClose = () => {
    setOpenTransactionModal(false);
  }

  return (
    <>
      <Box className=" container view-single-request-main">
        <Box className="view-single-request-conatiner" sx={{ marginTop: "95px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Box className="left-container">
                <Box className="top-view">
                  <Box className="left-section">
                    <Typography className="title font-28" component="h4">
                      {previousStepData && previousStepData?.project_title}
                    </Typography>
                    <Typography className="sub-title font-14" component="p">
                      Search more Auditing service requests | Posted {calculateDaysSinceCreation(previousStepData.createdAt)} ago
                    </Typography>
                  </Box>
                  <Box className="book-mark-btn">
                    {previousStepData && previousStepData?.is_bookmark ?
                      <BookmarkOutlinedIcon onClick={() => handleClickBookmark(previousStepData)} className="cursor-pointer" /> :
                      <BookmarkBorderOutlinedIcon onClick={() => handleClickBookmark(previousStepData)} className="cursor-pointer" />}
                  </Box>
                </Box>
                <Box className="disc-box">
                  <Typography className="title font-14" component="p">
                    {previousStepData && previousStepData?.describe_services}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box className="categoryCard" mt={3}>
                  <Grid container spacing={2}>
                    <Grid item md={4} sm={6} xs={12}>
                      <Box className="category-card">
                        <MonetizationOnIcon className="Icon" />
                        <Box pt={3}>
                          <Typography className="Heading font-24">
                            ${previousStepData && previousStepData?.budget_from} - ${previousStepData && previousStepData?.budget_to}
                          </Typography>
                          <Typography className="SubHeading  font-18">
                            {previousStepData?.budget === "Project Budget"
                              ? "Fixed-price"
                              : previousStepData?.budget}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Box className="category-card">
                        <ViewComfyAltIcon className="Icon" />
                        <Box pt={3}>
                          <Typography className="Heading font-24">
                            {previousStepData &&
                              previousStepData?.level_of_experience}
                          </Typography>
                          <Typography className="SubHeading  font-18">
                            Experience Level
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Box className="category-card">
                        <RepeatIcon className="Icon" />
                        <Box pt={3}>
                          <Typography className="Heading font-24">
                            {previousStepData?.work_time} Month
                          </Typography>
                          <Typography className="SubHeading font-18">
                            Project Duration
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box className="comapany-profile-skills" mt={3}>
                <Typography component="h4" className="title font-18">
                  Skills & Expertise
                </Typography>

                <Box mt={2}>
                  <Box className="skill-section">
                    <Grid container spacing={2} mt={1}>
                      {previousStepData?.skills ?
                        previousStepData?.skills?.map((item, index) => {
                          return (
                            <Grid xs="auto" ml={2}>
                              {" "}
                              <SkillChip key={index} label={item.skill_name} />{" "}
                            </Grid>
                          );
                        })
                        : previousStepData?.skillData?.map((item, index) => {
                          return (
                            <Grid xs="auto" ml={2}>
                              {" "}
                              <SkillChip key={index} label={item.skill_name} />{" "}
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Box className="activity-job-container">
                <Typography component="h4" className="title font-18">
                  Activity on this job
                </Typography>
                <Box className="activity-main">
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box className="card">
                        <Typography className="title font-22" component="h4">
                          {previousStepData?.applicationCount}
                        </Typography>
                        <Typography className="sub-title font-12" component="p">
                          Applications
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="card">
                        <Typography className="title font-22" component="h4">
                          {calculateDaysSinceCreation(previousStepData.lastViewDateTime ? previousStepData.lastViewDateTime : previousStepData.createdAt)} ago
                        </Typography>
                        <Typography className="sub-title font-12" component="p">
                          Last viewed by client
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="card">
                        <Typography className="title font-22" component="h4">
                          {previousStepData?.interviewing}
                        </Typography>
                        <Typography className="sub-title font-12" component="p">
                          Interviewing
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box className="activity-job-container">
                <Typography component="h4" className="title">
                  About the Client
                </Typography>
                <Box className="activity-main">
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box className="card">
                        <Typography className="title font-22" component="h4">
                          {previousStepData?.userData?.userInformation[0]?.country ? `${previousStepData?.userData?.userInformation[0]?.country}` : 'Address not available'}
                        </Typography>
                        <Typography className="sub-title font-12" component="p">
                          {previousStepData?.userData?.userInformation[0]?.address ? `${previousStepData?.userData?.userInformation[0]?.address}` : ''}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="card">
                        <Typography className="title font-22" component="h4">
                          {previousStepData?.userData?.postedJobs} jobs posted
                        </Typography>
                        <Typography className="sub-title font-12" component="p">
                          {previousStepData?.userData?.hireRate}% hire rate, {previousStepData?.userData?.openJobs} open jobs
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="card">
                        <Typography className="title font-22" component="h4">
                          ${previousStepData?.userData?.totalSpent} total spent
                        </Typography>
                        <Typography className="sub-title font-12" component="p">
                          {previousStepData?.userData?.totalHire} hires, {previousStepData?.userData?.totalActiveJob} active
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {/* Explore similer project section */}
              <Box className="explore-job-container" mt={3}>
                <Typography component="h4" className="heading font-28">
                  Explore similar jobs
                </Typography>
                <Box mt={3}>
                  <Grid container spacing={2}>
                    {similerProjectType &&
                      similerProjectType?.slice(0, 2)?.map((serviceVal, ind) => {
                        return (
                          <Grid item key={ind} xs={12} md={6} sm={6}>
                            <Box className="card-box">
                              <Box className="top-header">
                                <Box className="top-left">
                                  <Box className="pc-chip-wrep-browse">
                                    {calculateNewSinceCreation(serviceVal?.createdAt) === 'new' ?
                                      <Chip className="pc-chip" label={"New"} /> : ""}
                                  </Box>
                                  <Box className="top-sub-left">
                                    <Typography
                                      component="h4"
                                      className="font-16 title"
                                    >
                                      {serviceVal?.project_title}
                                    </Typography>
                                    <Typography
                                      component="p"
                                      className="font-12 sub-title"
                                    >
                                      {serviceVal?.budget === "Project Budget"
                                        ? "Fixed-price"
                                        : serviceVal?.budget} | Posted {calculateDaysSinceCreation(serviceVal?.createdAt)} ago
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box className="top-right">
                                  {serviceVal && serviceVal?.is_bookmark ?
                                    <Bookmarks onClick={() => handleClickBookmark(serviceVal)} className="bookmark-icon" />
                                    : <BookmarkBorderIcon onClick={() => handleClickBookmark(serviceVal)} className="bookmark-icon" />}
                                </Box>
                              </Box>
                              <Box className="card-body">
                                <Box className="top-view">
                                  <Box className="left-section">
                                    <Typography className="title font-12">
                                      {serviceVal?.budget === "Project Budget"
                                        ? "Fixed-price"
                                        : serviceVal?.budget}
                                    </Typography>
                                    <Typography
                                      component="p"
                                      className="sub-title font-16"
                                    >
                                      ${serviceVal?.budget_from} - ${serviceVal?.budget_to}
                                    </Typography>
                                  </Box>
                                  <Box className="right-section">
                                    <Typography
                                      component="h4"
                                      className="title font-12"
                                    >
                                      Experience Level
                                    </Typography>
                                    <Typography
                                      component="p"
                                      className="sub-title font-16"
                                    >
                                      {serviceVal?.level_of_experience}
                                    </Typography>
                                  </Box>
                                </Box>

                                <Box className="card-body-discription">
                                  {expandedStates[ind] ? (
                                    <>
                                      <Typography
                                        component="p"
                                        className="title font-14"
                                      >
                                        {serviceVal?.describe_services}
                                      </Typography>
                                      <Link onClick={() => handleViewLess(ind)}>
                                        <Typography
                                          variant="h6"
                                          sx={{
                                            color: "#398CD1",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            textDecoration: "underline",
                                            textDecorationColor: "#398CD1",
                                          }}
                                          mt={1}
                                        >
                                          View Less
                                        </Typography>
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <Typography
                                        component="p"
                                        className="title font-14"
                                        sx={{
                                          mt: "12px",
                                          color: "#FFFFFF",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          display: "-webkit-box",
                                          WebkitLineClamp: "2",
                                          WebkitBoxOrient: "vertical",
                                        }}
                                      >
                                        {serviceVal?.describe_services}
                                      </Typography>
                                      <Link onClick={() => handleViewMore(ind)}>
                                        <Typography
                                          variant="h6"
                                          sx={{
                                            color: "#398CD1",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            textDecoration: "underline",
                                            textDecorationColor: "#398CD1",
                                          }}
                                          mt={1}
                                        >
                                          View More
                                        </Typography>
                                      </Link>
                                    </>
                                  )}
                                </Box>
                                <Box className="button-list">
                                  {serviceVal?.skills ?
                                    serviceVal?.skills?.map(
                                      (skillVal, index) => (
                                        <Button key={index}>
                                          {skillVal.skill_name}
                                        </Button>
                                      )
                                    ) :
                                    serviceVal?.skillData?.map(
                                      (skillVal, index) => (
                                        <Button key={index}>
                                          {skillVal.skill_name}
                                        </Button>
                                      )
                                    )}
                                </Box>
                                <Box className="see-more-btn">
                                  <Link to={`/service-provider/view-single-request/${serviceVal?.id}`}>
                                    <Button
                                      variant="contained"
                                      startIcon={<AddIcon />}
                                    >
                                      See more
                                    </Button>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} className="right-side-section">
              <Box className="submit-appli-rqs-btn">
                <Stack direction="row" spacing={2}>
                  {profilestate ?
                    <Button
                      variant="contained"
                      startIcon={<ArrowForwardIcon />}
                      className="font-14"
                      onClick={submitRequest}
                    >
                      Submit application for this request
                    </Button>
                    :
                    <Button
                      variant="contained"
                      startIcon={<ArrowForwardIcon />}
                      className="font-14"
                      onClick={redirectTonBording}
                    >
                      Submit application for this request
                    </Button>
                  }

                </Stack>
              </Box>
              <Box mt={2}>
                <RightSideRaceSection
                  raceProject={previousStepData?.raceProject ? previousStepData?.raceProject : null}
                  raceActivity={{
                    totalSpent: previousStepData?.userData?.totalSpent,
                    jobsPosted: previousStepData?.userData?.postedJobs,
                    hireRate: previousStepData?.userData?.hireRate,
                    memberSince: previousStepData?.userData?.createdAt ? formatDate(previousStepData?.userData?.createdAt, 'MMMM dd, yy') : ''
                  }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setQuoteAmount('');
          setQuoteAmountError('');
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="send-custom-offer-container send-offer"
      >
        <Box sx={style}>
          <Box className="headContent cross-view-single">
            <IconButton className='cross-icon-modal' onClick={() => {
              setOpenModal(false);
              setQuoteAmount('');
              setQuoteAmountError('');
            }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography id="modal-modal-title" component="h2" mb={4}>
            Send a custom offer
          </Typography>
          <Box className="step1-container ">
            <Grid container spacing={2}>
              <Grid item md={12} sm={12}>
                <Box className="left-div">
                  <Box className="input-box" mb={3}>
                    <TextField
                      id="outlined-basic"
                      label="Quotation"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      placeholder="Quotation"
                      value={"$" + quoteAmount}
                      onChange={(e) => {
                        setQuoteAmount(
                          e.target.value.substring(1)
                        )
                        const err = validAmountCheck(e.target.value.substring(1));
                        if (err) setQuoteAmountError(err);
                        else setQuoteAmountError('');
                      }
                      }
                    />
                    {quoteAmountError && <p style={{ color: 'red' }}>{quoteAmountError}</p>}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="send-offer" textAlign={'center'}>
            <LoadingButton
              onClick={sendOffer}
              loading={loading}
              loadingPosition="start"
              variant="contained"
              type="submit"
              className="submit-btn"
            >
              Send Offer
            </LoadingButton>
          </Box>
        </Box>
      </Modal>

      {openTransactionModal ? <SpTransactionProgressModal identity={'send-offer'} confirmStake={confirmStake} propData={propData} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}
    </>
  );
};

export default ViewSingleRequest;
