// checkBalance.js
import { readContract } from "@wagmi/core";

/**
 * Fetch user wallet balance
 * @param {String} address user wallet address
 * @param {String} feeTokenType token type name
 * @returns user wallet balance
 */
async function checkBalance(address, token_address, token_abi) {
    try {

        const balanceOf = await readContract({
            address: token_address,
            abi: token_abi,
            account: address,
            functionName: "balanceOf",
            chainId: 6806,
            watch: true,
            args: [
                address // user wallet address
            ]
        });
        return Number(balanceOf);
    } catch (error) {
        console.error('Error in fetching wallet balance:', error);
        throw error; // propagate the error
    }
}

export { checkBalance };
