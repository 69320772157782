// changingOffer.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";
import convertToDecimal from "../Common/convertToDecimal";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Call changingOffer method for investor to edit the offer data
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Number} offerAmount offer amount 
 * @param {Number} offeredPercentage offer percentage 
 * @param {Number} offeredDuration offer duration 
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function changingOffer(address, contract_address, contract_abi, token_address, assetData, offerAmount, offeredPercentage, offeredDuration, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // Set active step to 2
        handleActiveStep(2);

        let amount = getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount);

        let changingOfferArgs = {}
        if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            changingOfferArgs = {
                address: contract_address,
                abi: contract_abi,
                account: address,
                chainId: 6806,
                functionName: "changingOffer",
                args: [
                    assetData?.offerDetails?.listing_id, // listing id / ipfs code id Integer
                    assetData?.offerDetails?.blockchainOfferId,  // offer id Integer
                    amount, // offer amount / offeredAmount
                    token_address, // currency token address
                    Number(Math.round(offeredPercentage * 100)), // offer rate of interest / offeredPercentage
                    Number(offeredDuration) // offer loan duration / offeredDuration
                ]
            }
        } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            changingOfferArgs = {
                address: contract_address,
                abi: contract_abi,
                account: address,
                chainId: 6806,
                functionName: "chaingingOffer",
                args: [
                    assetData?.offerDetails?.listing_id, // listing id / ipfs code id Integer
                    assetData?.offerDetails?.blockchainOfferId,  // offer id Integer
                    convertToDecimal(offerAmount) // offer amount / offeredAmount
                ]
            }
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT || assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT)) {
            changingOfferArgs = {
                address: contract_address,
                abi: contract_abi,
                account: address,
                chainId: 6806,
                functionName: "chaingingOffer",
                args: [
                    assetData?.offerDetails?.listing_id, // listing id / ipfs code id Integer
                    assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1, // debtTypeId
                    assetData?.offerDetails?.blockchainOfferId,  // offer id Integer
                    convertToDecimal(offerAmount), // offer amount / offeredAmount
                    Number(Math.round(offeredPercentage * 100)), // offer percentage / percentage
                    Number(offeredDuration) // offer duration / duration
                ]
            }
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.EQUITY)) {
            changingOfferArgs = {
                address: contract_address,
                abi: contract_abi,
                account: address,
                chainId: 6806,
                functionName: "chaingingOffer",
                args: [
                    assetData?.offerDetails?.listing_id, // listing id / ipfs code id Integer
                    assetData?.offerDetails?.blockchainOfferId,  // offer id Integer
                    convertToDecimal(offerAmount), // offer amount / offeredAmount
                    Number(Math.round(assetData?.editedData[1]?.value * 100))
                ]
            }
        }

        const { request: changingOffer } = await prepareWriteContract(changingOfferArgs);
        const { hash } = await writeContract(changingOffer);

        if (hash) {
            const data = await waitForTransaction({ hash });
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(3);
                setTimeout(() => {
                    handleSuccess(assetData);
                    handleModalClose();
                    handleActiveStep(-1);
                    return { data };
                }, 1000);
            } else {
                handleModalClose();
                handleActiveStep(-1);
                showSnackbar("Transaction Failed", 'error');
            }
        }
    } catch (error) {
        console.error('Error calling changing offer:', error);
        throw error; // propagate the error
    }
}

export { changingOffer };
