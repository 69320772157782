// calculateMinimumPrepaymentAmount.js

import { readContract } from "@wagmi/core";
import convertToDecimal from "../Common/convertToDecimal";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID } from "../../constants";

/**
 * Fetch user wallet balance
 * @param {Object} payEMIData asset details 
 * @returns emi amount for current date
 */
async function calculateMinimumPrepaymentAmount(contract_address, contract_abi, payEMIData, amount) {
    try {
        if (contract_address) {
            let args = [
                payEMIData.listing_id,
                convertToDecimal(amount)
            ]
            if (payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                args = [
                    payEMIData.listing_id,
                    payEMIData?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1,
                    convertToDecimal(amount)
                ]
            }
            const getMinimumPrepaymentData = await readContract({
                address: contract_address,
                abi: contract_abi,
                functionName: "calculateMinimumPrepaymentAmount",
                chainId: 6806,
                watch: true,
                args: args
            });

            return getMinimumPrepaymentData
        }
    } catch (error) {
        console.error('Error fetching prepayment amount:', error);
        throw error; // propagate the error
    }
}

export { calculateMinimumPrepaymentAmount };
