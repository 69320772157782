// dicStake.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";
// import { INVESTMENT_TYPE_ID } from "../../constants";

/**
 * Pay stake amount by DIC member to start review process
 * @param {String} address user wallet address
 * @param {Number} proposalId asset's blockchain id
 * @param {Number} stakeAmt amount to stake
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function dicStake(address, contract_address, contract_abi, token_address, user_id, asset_id, proposalId, stakeAmt, showSnackbar, handleActiveStep, handleModalClose, handleSuccess, assetData) {
    try {
        // set active step to 2
        handleActiveStep(2)

        // checking for project/proposal id of blockchain
        if (!proposalId) {
            showSnackbar("Project ID not found on the blockchain.", 'error');
            handleModalClose();
            handleActiveStep(-1)
            return
        }
        let args = [
            Number(proposalId), // asset's blockchain id
            token_address, // _feeToken
            getCurrencyTypeDecimal(assetData?.feeTokenType, stakeAmt)  // Stake amount
        ]
        // if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
        //     args = [
        //         Number(proposalId), // asset's blockchain id
        //         token_address, // _feeToken
        //         getCurrencyTypeDecimal(assetData?.feeTokenType, stakeAmt),  // Stake amount
        //         assetData?.id
        //     ]
        // }

        const { request: stakeInPool } = await prepareWriteContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            chainId: 6806,
            functionName: "stakeInPool",
            args
        })
        const { hash } = await writeContract(stakeInPool);

        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess(data, stakeAmt, user_id, asset_id)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error calling dic stake method:', error);
        throw error; // propagate the error
    }
}

export { dicStake };