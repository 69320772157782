// setRepaymentConfigs.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import convertToDecimal from "../Common/convertToDecimal";
import convertToTwoDecimal from "../Common/convertToTwoDecimal";

/**
 * Approve the tokens for spender contract
 * @param {String} address user wallet address
 * @param {String} spenderAddress spender contract address
 * @param {String} allowanceAmt  amount to approve
 * @returns transaction data
 */
async function setRepaymentConfigs(address, contract_address, contract_abi, assetData, user_id, asset_id, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {

    try {
        let repaymentConfigData = assetData?.repaymentData;

        // Now check for allowance RACE Tokens
        const { request: setRepaymentConfigs } = await prepareWriteContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            chainId: 6806,
            functionName: "setRepaymentConfigs",
            args: [
                assetData?.blockchain_id,
                {
                    emiPenaltyPercent: convertToTwoDecimal(repaymentConfigData?.emiPenaltyPercent),
                    emiPenaltyAmount: convertToDecimal(repaymentConfigData?.emiPenaltyAmount),
                    repossessionContinuous: repaymentConfigData?.repossessionContinuous,
                    repossessionContiguous: repaymentConfigData?.repossessionContiguous,
                    yeildMaintainancePercent: convertToTwoDecimal(repaymentConfigData?.yeildMaintainancePercent),
                    yeildMaintainanceAmount: convertToDecimal(repaymentConfigData?.yeildMaintainanceAmount),
                    isMinimumPrepayment: repaymentConfigData?.isMinimumPrepayment,
                    requiredMonthForMinimumPrepayment: repaymentConfigData?.isMinimumPrepayment ? repaymentConfigData?.requiredMonthForMinimumPrepayment : 0,
                    minPrepaymentPercent: convertToTwoDecimal(repaymentConfigData?.isMinimumPrepayment ? repaymentConfigData?.minPrepaymentPercent : 0),
                    maxPrepaymentPercent: convertToTwoDecimal(repaymentConfigData?.isMinimumPrepayment ? repaymentConfigData?.maxPrepaymentPercent : 0),
                    minPrepaymentFeesPercent: convertToTwoDecimal(repaymentConfigData?.isMinimumPrepayment ? repaymentConfigData?.minPrepaymentFeesPercent : 0),
                    minPrepaymentFeesAmount: convertToDecimal(repaymentConfigData?.isMinimumPrepayment ? repaymentConfigData?.minPrepaymentFeesAmount : 0)
                }
            ]
        })

        console.log("setRepaymentConfigs...", setRepaymentConfigs)
        const { hash } = await writeContract(setRepaymentConfigs);

        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(2)
                setTimeout(() => {
                    handleSuccess(assetData, asset_id, user_id)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error minting asset:', error);
        throw error; // propagate the error
    }
}

export { setRepaymentConfigs };