import React, { useEffect, useState } from 'react';
import {
    Modal, Box, Typography, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { ccyFormat, formatDate } from '../../../../Utils/utils';

// Define styles
const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    header: {
        fontSize: 24,
        marginBottom: 20,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    section: {
        marginBottom: 20,
    },
    invoiceInfo: {
        marginBottom: 10,
    },
    billTo: {
        marginBottom: 20,
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderWidth: 1,
        borderColor: '#333',
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#f2f2f2',
        borderBottomWidth: 1,
        borderBottomColor: '#333',
        padding: 5,
        fontWeight: 'bold',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
        padding: 5,
    },
    tableCell: {
        flex: 1,
        padding: 2,
        textAlign: 'center',
    },
    totalRow: {
        flexDirection: 'row',
        padding: 5,
        fontWeight: 'bold',
    },
    totalLabel: {
        flex: 3,
        textAlign: 'right',
        paddingRight: 5,
    },
    totalValue: {
        flex: 1,
        textAlign: 'center',
    },
    footer: {
        marginTop: 20,
        fontSize: 12,
        textAlign: 'center',
    },
});

const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};

const PdfViewer = ({ contractData, isOpen, setIsOpenModal, milestoneData }) => {
    const [openModal, setOpenModal] = useState(false);
    useEffect(() => {
        if (isOpen) {
            setOpenModal(true);
            setIsOpenModal(true);
        } else {
            setOpenModal(false);
            setIsOpenModal(false);
        }
    }, [isOpen]);
 
    return (
        <div>
            <Modal
                className="projectDocumentsModal"
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setIsOpenModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                fullWidth={true}
            >
                <Box sx={styleModal}>
                    <Box className="header">
                        <Typography id="modal-modal-title font-28" component="h2">
                            Invoice
                        </Typography>
                        <IconButton className='close-btn' onClick={() => { setOpenModal(false); setIsOpenModal(false); }}>
                            <CloseIcon className="cross-icon-modal" />
                        </IconButton>
                    </Box>
                    <OverlayScrollbarsComponent defer
                        style={{ width: '100%', height: '100%' }}
                    >
                        <PDFViewer width={'100%'} height={'80%'}>
                            <Document>
                                <Page size="A4" style={styles.page}>
                                    {/* Header */}
                                    <Text style={styles.header}>Invoice</Text>
                                    {contractData && !milestoneData &&
                                        <>
                                            <View style={styles.invoiceInfo}>
                                                <Text>Invoice No: INV0000{contractData?.id}</Text>
                                                <Text>Date: {contractData?.delivery_date ? formatDate(new Date(contractData?.delivery_date), 'MMM d, yyyy') : ' --'}</Text>
                                                <Text>Due Date: 2023-12-14</Text>
                                            </View>
                                            <View style={styles.billTo}>
                                                <Text>Bill To:</Text>
                                                <Text>{contractData?.clientData?.first_name} {contractData?.clientData?.last_name}</Text>
                                                <Text>{contractData?.clientData?.userInformation[0]?.address}</Text>
                                                <Text>{contractData?.clientData?.userInformation[0]?.city}, {contractData?.clientData?.userInformation[0]?.country}</Text>
                                            </View>
                                            <View style={styles.tableContainer}>
                                                {/* Table Header */}
                                                <View style={styles.tableHeader}>
                                                    <Text style={styles.tableCell}>ITEM</Text>
                                                    <Text style={styles.tableCell}>Qty</Text>
                                                    <Text style={styles.tableCell}>DURATION</Text>
                                                    <Text style={styles.tableCell}>PRICE</Text>
                                                </View>

                                                {/* Table Rows */}
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableCell}>Auditing For {contractData?.title}</Text>
                                                    <Text style={styles.tableCell}>1</Text>
                                                    <Text style={styles.tableCell}></Text>
                                                    <Text style={styles.tableCell}>${ccyFormat(contractData?.project_fees)}</Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableCell}>SUBTOTAL</Text>
                                                    <Text style={styles.tableCell}></Text>
                                                    <Text style={styles.tableCell}></Text>
                                                    <Text style={styles.tableCell}>${ccyFormat(contractData?.project_fees)}</Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableCell}>SERVICE FEE</Text>
                                                    <Text style={styles.tableCell}></Text>
                                                    <Text style={styles.tableCell}></Text>
                                                    <Text style={styles.tableCell}>- ${ccyFormat(contractData?.spv_service_fees ?? 0)}</Text>
                                                </View>

                                                {/* Total Row */}
                                                <View style={styles.totalRow}>
                                                    <Text style={styles.totalLabel}>Total:</Text>
                                                    <Text style={styles.totalValue}>${(ccyFormat(contractData?.project_fees) - ccyFormat(contractData?.spv_service_fees ?? 0)).toFixed(2)}</Text>
                                                </View>
                                            </View>
                                        </>
                                    }

                                    {contractData && milestoneData &&
                                        <>
                                            <View style={styles.invoiceInfo}>
                                                <Text>Invoice No: INV0000{contractData?.id}ML-</Text>
                                                <Text>Date: {contractData?.delivery_date ? formatDate(new Date(contractData?.delivery_date), 'MMM d, yyyy') : ' --'}</Text>
                                                <Text>Due Date: 2023-12-14</Text>
                                            </View>
                                            <View style={styles.billTo}>
                                                <Text>Bill To:</Text>
                                                <Text>{contractData?.clientData?.first_name} {contractData?.clientData?.last_name}</Text>
                                                <Text>{contractData?.clientData?.userInformation[0]?.address}</Text>
                                                <Text>{contractData?.clientData?.userInformation[0]?.city}, {contractData?.clientData?.userInformation[0]?.country}</Text>
                                            </View>
                                            <View style={styles.tableContainer}>
                                                {/* Table Header */}
                                                <View style={styles.tableHeader}>
                                                    <Text style={styles.tableCell}>ITEM</Text>
                                                    <Text style={styles.tableCell}>Qty</Text>
                                                    <Text style={styles.tableCell}>DURATION</Text>
                                                    <Text style={styles.tableCell}>PRICE</Text>
                                                </View>

                                                {/* Table Rows */}
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableCell}>{milestoneData?.description}</Text>
                                                    <Text style={styles.tableCell}>1</Text>
                                                    <Text style={styles.tableCell}></Text>
                                                    <Text style={styles.tableCell}>${ccyFormat(milestoneData?.Amount)}</Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableCell}>SUBTOTAL</Text>
                                                    <Text style={styles.tableCell}></Text>
                                                    <Text style={styles.tableCell}></Text>
                                                    <Text style={styles.tableCell}>${ccyFormat(milestoneData?.Amount)}</Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableCell}>SERVICE FEE</Text>
                                                    <Text style={styles.tableCell}></Text>
                                                    <Text style={styles.tableCell}></Text>
                                                    <Text style={styles.tableCell}>- ${ccyFormat(milestoneData?.spv_service_fee ?? 0)}</Text>
                                                </View>

                                                {/* Total Row */}
                                                <View style={styles.totalRow}>
                                                    <Text style={styles.totalLabel}>Total:</Text>
                                                    <Text style={styles.totalValue}>${(ccyFormat(milestoneData?.Amount) - ccyFormat(milestoneData?.spv_service_fee ?? 0)).toFixed(2)}</Text>
                                                </View>
                                            </View>
                                        </>
                                    }

                                    {/* Footer */}
                                    <Text style={styles.footer}>Thank you for your business!</Text>
                                </Page>
                            </Document>
                        </PDFViewer>
                    </OverlayScrollbarsComponent>
                </Box>
            </Modal>
        </div>
    );
};

export default PdfViewer;