// repaymentConfigDetail.js

import { readContract } from "@wagmi/core";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} address user wallet address
 * @returns user wallet allowance
 */
async function repaymentConfigDetail(contract_address, contract_abi, listing_id) {
    try {

        // Now check for allowance Tokens
        const repaymentConfig = await readContract({
            address: contract_address,
            abi: contract_abi,
            functionName: "repaymentConfigDetail",
            chainId: 6806,
            args: [
                listing_id  // asset listing id
            ]
        })
        return repaymentConfig;
    } catch (error) {
        console.error('Error fetching repaymentConfig:', error);
        throw error; // propagate the error
    }
}

export { repaymentConfigDetail };
