import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Box, Button, Chip, Typography, Grid, Tooltip, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../../Contexts/SnackbarContext";
import { useSocket } from '../../../../Contexts/SocketContext';
import { GetApi } from '../../../../Api/Api';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import imgBg from "../../../../Assets/Images/service-provider/img-card.png";
import DownloadIcon from '@mui/icons-material/Download';
import ConfirmModal from "../../../../Components/Modal/Confirm/Confirm";
import RatingModal from './RatingModal/RatingModal';
import DeliveryConfirmModal from './DeliveryConfirmModal/DeliveryConfirmModal'
import { updateApi } from "../../../../Api/Api";
import { formatDate, fetchAndDownloadFile } from '../../../../Utils/utils';
import SpTransactionProgressModal from '../../../SmartContracts/SpTransactionProgressModal';
import InvoiceViewer from '../InvoiceViewer/InvoiceViewer'

export default function DeliveryJob({ contractData, getContractData }) {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const socket = useSocket();
  const [openModal, setOpenModal] = useState(false);
  const [deliveryId, setDeliveryId] = useState(null);
  const [isOpenRatingModal, setIsOpenRatingModal] = useState(false);
  const [rejectReason, setRejectReason] = useState(null);
  const [deliveryData, setDeliveryData] = useState([]);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [openTransactionModalReject, setOpenTransactionModalReject] = useState(false)
  const [openTransactionModalMilestone, setOpenTransactionModalMilestone] = useState(false);
  const [openTransactionModalRejectMilestone, setOpenTransactionModalRejectMilestone] = useState(false)
  const [ openPdfViewer, setOpenPdfViewer ] = useState(false);
  const [ currentMilestoneData, setCurrentMilestoneData ] = useState(undefined);

  const fetchData = useCallback(() => {
    let url = `/services/getContractDelivery/${contractData?.id}`;
    GetApi(url).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          let number = 1;
          const updatedData = res.data.data.map((item, index) => {
            const updData = {
              ...item,
              sno: item?.status === 1 ? number++ : number,
            }
            // if (index === 0) {
            //   number = 1;
            // } else {
            //   if (item?.status === 1) {
            //     number = number + 1;
            //   }
            // }
            return updData;
          });
          setDeliveryData(updatedData);
        } else {
          setDeliveryData([]);
        }
      }
    })
  }, [contractData]);

  useEffect(() => {
    fetchData();
  }, [contractData]);

  /**
 * Function for confirm staking
 * @param {*} data 
 */
  const confirmStake = (data) => {
    AcceptRejectDelivery('confirm')
    setOpenModal(false);
    setOpenTransactionModal(false)
  }

  /**
 * Function for confirm staking
 * @param {*} data 
 */
  const confirmStakeMilestone = (data) => {
    AcceptRejectDelivery('confirm')
    setOpenModal(false);
    setOpenTransactionModalMilestone(false)
  }

  /**
 * Function for confirm staking
 * @param {*} data 
 */
  const confirmStakeReject = (data) => {
    AcceptRejectDelivery('')
    setOpenModal(false);
    setOpenTransactionModalReject(false)
  }
  /**
 * Function for confirm staking
 * @param {*} data 
 */
  const confirmStakeRejectMilestone = (data) => {
    AcceptRejectDelivery('')
    setOpenModal(false);
    setOpenTransactionModalRejectMilestone(false)
  }

  /**
* Function to close Transection Modal
*/
  const handleModalClose = (data) => {
    setOpenTransactionModal(false);
  }

  /**
* Function to close Transection Modal
*/
  const handleModalCloseMilestone = (data) => {
    setOpenTransactionModalMilestone(false);
  }

  /**
* Function to close Transection Modal
*/
  const handleModalCloseReject = (data) => {
    setOpenTransactionModalReject(false);
  }

  /**
* Function to close Transection Modal
*/
  const handleModalCloseRejectMilestone = (data) => {
    setOpenTransactionModalRejectMilestone(false);
  }

  const AcceptRejectDelivery = async (action) => {
    let payload;
    if (action === 'confirm') {
      payload = {
        status: 1,
        contractId: contractData.id,
      }
    } else {
      payload = {
        status: 2,
        contractId: contractData.id,
        deliveryCancel: true,
        reject_reason: rejectReason
      }
    }
    let url = `/services/updateContractDeliveryStatus/${deliveryId}`;

    updateApi(url, payload).then((res) => {
      if (res?.status === 200) {
        if (res.data.code === 200) {
          showSnackbar(res?.data?.message, 'success');
          setOpenModal(false);
          if (res?.data?.rating) setIsOpenRatingModal(true);
          else getContractData();
          // navigate(`/service-proposer-dashboard/myorder`);
        } else {
          showSnackbar(res?.data?.message, 'error');
        }
      }
    })
  }




  const handleAction = (action) => {
    // let payload;
    // if(action === 'confirm'){
    //   payload = {
    //     status: 1,
    //     contractId: contractData.id,
    //   }
    // }else{
    //   payload = {
    //     status: 2,
    //     contractId: contractData.id,
    //     deliveryCancel: true,
    //     reject_reason: rejectReason
    //   }
    // }
    // let url = `/services/updateContractDeliveryStatus/${deliveryId}`;
    // console.log("url---",url);
    // updateApi(url, payload).then((res) => {
    //   if (res?.status === 200) {
    //     if(res.data.code === 200){
    //       showSnackbar(res?.data?.message, 'success');
    //       setOpenModal(false);
    //       if(res?.data?.rating)setIsOpenRatingModal(true);
    //       else getContractData();
    //       // navigate(`/service-proposer-dashboard/myorder`);
    //     }else{
    //       showSnackbar(res?.data?.message, 'error');
    //     }
    //   }
    // })
    if (action === "confirm") {
      if (contractData?.project_term === 'project') setOpenTransactionModal(true);//approveContractDelivery
      if (contractData?.project_term === 'milestone') setOpenTransactionModalMilestone(true);// approveMileStone
    } else {
      if (contractData?.project_term === 'project') setOpenTransactionModalReject(true);// rejectContractDelivery
      if (contractData?.project_term === 'milestone') setOpenTransactionModalRejectMilestone(true);// rejectMilestone
      // rejectContractDelivery
    }
  }

  const handleCloseRatingModal = () => {
    setIsOpenRatingModal(false);
    getContractData();
  }
  return (
    <><Box className="activity-container">
      {deliveryData && deliveryData.length > 0 ? deliveryData.map((row, index) => (
        <><Box className="date-btn">
          <Box style={{ marginTop: `${index > 0 ? '10px' : '1px'}` }}>
            <Chip className="chip-grey" label={`Delivery #${row?.sno}`} />&nbsp;
            {row?.status === 0 && <Chip className="chip-pending" label={`Pending`} />}&nbsp;
            {row?.status === 1 && <Chip className="chip-accepted" label={`Accepted`} />}&nbsp;
            {row?.status === 2 && <Chip className="chip-rejected" label={`Rejected`} />}
          </Box>
        </Box><Box className="user-chat">
            <Box className="chat-left">
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg" />
            </Box>
            <Box className="chat-right">
              <Typography className="font-16">{row?.serviceProviderData?.first_name} {row?.serviceProviderData?.last_name}</Typography>
              <Typography className="font-12 text-blueGray-300">
                {formatDate(row.createdAt, 'MMM dd, h:mm a')}
              </Typography>
              {contractData && contractData?.project_term === 'milestone' && row?.milestoneData &&
                <Box className="msg-box">
                  <Stack direction="row" justifyContent="space-between">
                    <Typography className="font-16">Milestone Description</Typography>
                    {row?.status !== 2 &&
                      <><Typography
                        className="text-golden text-underline font-12 view-invoice-text cursor-pointer float-right"
                        onClick={() => {
                          setOpenPdfViewer(true);
                          setCurrentMilestoneData(row?.milestoneData)
                        } }>
                        View Invoice
                      </Typography></>
                    }
                  </Stack>
                  <Typography className="font-12">
                    {row?.milestoneData?.description}
                  </Typography>
                </Box>
              }
              <Box className="msg-box">
                <Typography className="font-16">Message</Typography>
                <Typography className="font-12">
                  {row?.message}
                </Typography>
              </Box>
              {row?.reject_reason &&
                <Box className="msg-box">
                  <Typography className="font-16">Rejection Reason</Typography>
                  <Typography className="font-12">
                    {row?.reject_reason}
                  </Typography>
                </Box>}
              <Box mt={2}>
                <Grid container spacing={1} >
                  {row.documents && row.documents.length > 0 && row.documents.map((doc) => (
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box className="download-img-card">
                        <Box className="download-img-wrapper">
                          <img src={imgBg} alt="" />
                        </Box>
                        <Box className="img-box-bottom">
                          <Box className="left-div">
                            <Typography className="font-16">{doc?.original_name}</Typography>
                            <Typography className="font-12 text-blueGray-300">
                              {doc?.size}
                            </Typography>
                          </Box>
                          <Box className="right-div">
                            {/* <img src={downloadIcon} alt="" /> */}
                            <Tooltip title={'download'} arrow>
                              <DownloadIcon style={{ color: '#10B981', cursor: 'pointer' }} onClick={() => {
                                fetchAndDownloadFile(doc.path, doc.original_name);
                              }} />
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              {row?.status === 0 && (
                <><Box mt={2}>
                  <Button onClick={() => { setOpenModal(true); setDeliveryId(row?.id); }} variant="contained" startIcon={<DoneAllIcon />} className="race-btn-req-provider">
                    Mark Service As Complete
                  </Button>
                </Box><Box mt={2}>
                    <Typography className="font-12 text-blueGray-300">
                      Once service has been marked as completed, the funds will be released to the Service Provider. Please ensure you are happy with the deliverables before clicking this button.
                    </Typography>
                  </Box></>
              )}
            </Box>
          </Box></>
      )) : <Typography className="font-12 text-blueGray-300" style={{ height: '50vh', textAlign: 'center' }}>
        Delivery data not available
      </Typography>}
    </Box>
      {/* <ConfirmModal title={'Confirmation'} description={'Are You Sure Want To Confirm It!'} isOpen={openModal} setIsOpenModal={setOpenModal} handleAction={handleAction} /> */}
      <RatingModal onOpen={isOpenRatingModal} handleClose={handleCloseRatingModal} contractId={contractData?.id} spvUserId={contractData?.serviceProviderData?.id} />

      {openTransactionModal ? <SpTransactionProgressModal identity={'approve-contract-delivery'} confirmStake={confirmStake} propData={contractData} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}

      {openTransactionModalMilestone ? <SpTransactionProgressModal identity={'approve-milestone'} confirmStake={confirmStakeMilestone} propData={contractData} handleModalClose={handleModalCloseMilestone} openTransactionModal={openTransactionModalMilestone} /> : null}

      {openTransactionModalReject ? <SpTransactionProgressModal identity={'reject-contract-delivery'} confirmStake={confirmStakeReject} propData={contractData} handleModalClose={handleModalCloseReject} openTransactionModal={openTransactionModalReject} /> : null}

      {openTransactionModalRejectMilestone ? <SpTransactionProgressModal identity={'reject-milestone'} confirmStake={confirmStakeRejectMilestone} propData={contractData} handleModalClose={handleModalCloseRejectMilestone} openTransactionModal={openTransactionModalRejectMilestone} /> : null}
      <InvoiceViewer contractData={contractData} isOpen={openPdfViewer} setIsOpenModal={setOpenPdfViewer} milestoneData={currentMilestoneData}/>
      <DeliveryConfirmModal
        onOpen={openModal}
        handleClose={setOpenModal}
        rejectReason={rejectReason}
        setRejectReason={setRejectReason}
        handleAction={handleAction} />
    </>
    
  )
}
